.nav-bg {
  border-bottom: 1px solid #a0c8f6;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.text-dark {
  font-weight: 700;
}

.navbar-logo {
  height: 36px;
  width: auto;
}
