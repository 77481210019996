.sidebar {
  position: sticky;
}

.sidebar-border {
  min-height: 93vh;
}

.sidebar-button:hover {
  background-color: #f2f2f2 !important;
}

.rotate-down,
.rotate-right {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
}

.rotate-right {
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;
}

.hover-effect:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
