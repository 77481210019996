.card-images {
  max-height: 200px;
}

.dropdown-button {
  background-color: white !important;
}

.active {
  background-color: gray !important;
}

.quantity {
  border: 2px solid #6c757d !important;
}
